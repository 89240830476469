import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';

const Printing = () => (
  <Layout>
    <Helmet
      title="Velgama - Spaudos darbai"
      meta={[
        { name: 'description', content: 'Kalendoriai, papkės, lankstinukai, skrajutės, vizitinės kortelės, iškilūs bei šilkografiniai lipukai' },
      ]}
    />

    <div id="main">
      <Banner
        h1="Spaudos darbai"
        paragraph="Kalendoriai, papkės, lankstinukai, skrajutės, vizitinės kortelės, iškilūs bei šilkografiniai lipukai ir kita"
        paragraph2="Sukirtimas, įrišimas, bigavimas..."
        bannerType="printing-banner"
      />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />

      </section>
    </div>
  </Layout>
);

const galleryQuery = graphql`
query printingImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-printing" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 1200, maxHeight: 1000){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default Printing;
